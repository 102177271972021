@import "animations.css";
@import "confirm.css";
@import "fields.css";
@import "menu.css";
@import "popup.css";
@import "table.css";

@media screen and (max-width: 400px) {
    :root {
        --fontSize: 10px !important;
        --boxShadow: 0px 0px 5px 0px #000000 !important;  
    }
}
@media screen and (max-width: 600px) {
    .root {
        flex-direction: column !important;
        justify-content: flex-start !important;
    }
}
@media screen and (min-width: 400px) and (max-width: 800px) {
    :root {
        --fontSize: 15px !important;
        --boxShadow: 0px 0px 7px 0px #000000 !important;  
    }
}

:root {
    --boxShadow: 0px 0px 10px 0px #000000;  
    --boxShadowReduced: 0px 0px 2px 0px #000000;  
    --bgColor: aliceblue;
    --btColor: rgba(63, 125, 224, 0.3);
    --btColorFull: rgba(63, 125, 224, 0.8);
    --disabledColor: rgba(63, 125, 224, 0.2);
    --successColor: rgba(178, 243, 166, 0.4);
    --unsuccessColor: rgba(229, 139, 139, 0.4);
    --fontSize: 20px;
}

@font-face {
    font-family: "JMH Typewriter";
    src: url("./fonts/JMH Typewriter.ttf");
}

body {
    background-color: var(--bgColor);
    font-family: "JMH Typewriter";
    font-size: var(--fontSize);
    margin: unset;
}

.root {
    box-sizing: unset;
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
}

h1 {
    margin: unset;
    text-align: center;
    width: -webkit-fill-available;
    padding: 0.5em 1em;
    border-radius: 0.5em;
}
h2 {
    margin: unset;
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
    padding: 0.5em 1em;
    border-radius: 0.5em;
}
h3 {
    margin: unset;
    font-size: 1em;
    text-align: center;
    padding: 0.5em 1em;
    border-radius: 0.5em;
}
s {
    text-decoration: unset;
    color: red;
}
line {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0.6em;
}
line img {
    width: 3em;
}

.commandContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: -webkit-fill-available;
    padding: 1em;
    overflow: scroll;
}
.commandContainer::-webkit-scrollbar {
    display: none;
}

.elementContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: fit-content;
    width: 45%;
    padding: 1vw;
    box-shadow: var(--boxShadow);
    border-radius: 1em;
    margin: 1vw;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.click:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.copyright {
    position: fixed;
    bottom: 0.5em;
    right: 0.5em;
    font-size: 0.8em;    
}

.clock {
    position: fixed;
    top: 0.5em;
    right: 0.5em;
    font-size: 1.5em;
}


.border {
    border: 0.1vw solid #000;
    border-radius: 1em;
}
.eventoLine {
    width: 95%;
    margin-bottom: 0.5em;
    font-size: 0.8em;
}
.eventoLine h3 {
    width: -webkit-fill-available;
    text-transform: uppercase;
}
.eventoLine img {
    width: 3em !important;
}
.eventoLine line {
    width: unset;
}

.borderBlock {
    width: 100%;
    margin: 0.5em 0px;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.1em solid #000;
    border-radius: 1em;
}
.borderBlock .borderBlock {
    width: 98%;
}

.elemBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: var(--boxShadow);
    border-radius: 0.5em;
    width: 10em;
    height: 10em;
    margin: 0.5em;
    padding: 0.5em;
}

.cliccable:hover {
    cursor: pointer;
    text-decoration: underline;
}

.loginContainer {
    margin: auto;
    box-shadow: var(--boxShadow);
    border-radius: 1em;
    padding: 1em 2em 0.5em 2em;
    margin-top: 15vh;
    text-align: center;
}
.loginContainer h1 {
    padding-top: unset;
}
