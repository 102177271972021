
@media screen and (max-width: 800px) {
    .confirm {
        width: 80vw !important;
        height: 80vw !important;
    }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
    .confirm {
        width: 50vw !important;
        height: 50vw !important; 
    }
}

.confirmContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.confirm {
    width: 40vw;
    height: 40vw;
    max-height: 80vh;
    text-align: center;
    border-radius: 3vw;
    background-color: var(--bgColor);
    box-shadow: var(--boxShadow);  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.confirm h2 {
    margin-top: 1em;
    font-size: 1.8em;
}

.confirm line {
    justify-content: space-around;
    margin-bottom: 1.5em;
}

.confirm button {
    font-size: 2em;
    padding: 0.5em 1.5em;
}

.confirm span {
    width: 90%;
    height: 60%;
    font-size: 1.5em;
    word-wrap: break-word;
}
