
@media screen and (max-width: 600px) {
    .menuLine {
        flex-direction: row !important;
        width: -webkit-fill-available !important;
        margin-top: 3em;
        padding: unset;
        min-height: 10em;
    }
}

.menuLine {
    flex-direction: column;
    justify-content: flex-start;
    width: 10em;
    overflow-y: scroll;
}
.menuLine::-webkit-scrollbar {
    display: none;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: var(--boxShadow);
    padding: 0.8em 0.5em;
    margin: 0.5em;
    border-radius: 1em;
    width: -webkit-fill-available;
}
.menu:hover {
    cursor: pointer;
    transform: scale(1.1);
}
.menu span {
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    height: 1.2em;
    margin-top: 0.2em;
}
.menu img {
    width: 4em;
}