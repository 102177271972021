
@media screen and (max-width: 400px) {
    .tableContainer {
        max-height: 60% !important;
    }
}
@media screen and (max-width: 600px) {
    .tableContainer {
        max-height: 65% !important;
    }
}

.tableContainer {
    width: 100%;
    max-height: 70%;
    overflow: auto;
}

table {
    text-align: center;
    border-spacing: 0px;
    font-size: 0.9em;
    position: relative;
    width: 100%;
}

tr:hover {
    background-color: var(--disabledColor);
    cursor: pointer;
}

tr img {
    width: 0.9em;
    height: 0.9em;
}
tr img:hover {
    cursor: pointer;
    transform: scale(1.1);
}

th {
    padding: 0.5em;
    font-size: 1em;
    background-color: rgba(63, 125, 224, 1);
    text-transform: uppercase;
    position: sticky;
    top: 0;
}

td {
    font-size: 0.9em;
}